.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1400;
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 0 10px;
}

.header-title {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.header-title:hover {
  color: #ff9800;
}

.header-icon {
  font-size: 1.5rem;
  transition: transform 0.3s ease;
  animation: rotate 2s linear infinite;
  color: var(--secondary-color);
}

.header-title:hover .header-icon {
  transform: rotate(20deg);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}