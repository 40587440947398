/* Definicija CSS varijabli */
:root {
  --header-height: 60px;
  --primary-color: #1a73e8;
  --secondary-color: #ffa726;
  --background-color: #121212;
  --card-background: #1e1e1e;
  --shadow-color: rgba(26, 115, 232, 0.4);
  --border-radius: 8px;
  --font-family-heading: 'Poppins', sans-serif;
  --font-family-body: 'Roboto', sans-serif;
  --transition-speed: 0.3s;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-bold: 700;
  --text-color: #e0e0e0;
}

body {
  font-family: var(--font-family-body);
  margin: 0;
  padding: 0;
  background: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  transition: background var(--transition-speed) ease, color var(--transition-speed) ease;
}

.app-container {
  max-width: 800px;
  margin: 0 auto 20px;
  padding: 20px;
  padding-top: calc(var(--header-height) + 20px);
  background: var(--card-background);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 12px var(--shadow-color);
  text-align: center;
  transition: box-shadow var(--transition-speed) ease, background var(--transition-speed) ease;
}

.app-container:hover {
  box-shadow: 0 6px 20px var(--shadow-color);
}

.btn-primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  font-size: 0.9rem;
  color: #FFFFFF;
  background: var(--primary-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background var(--transition-speed) ease, transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
  margin-top: 20px;
  font-weight: var(--font-weight-bold);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

.btn-primary:hover {
  background: #005bb5;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.btn-primary:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.btn-primary:disabled {
  background: #555555;
  cursor: not-allowed;
  box-shadow: none;
}

.status {
  margin: 15px 0;
  font-size: 1rem;
  color: var(--text-color);
  font-weight: var(--font-weight-bold);
  position: relative;
  display: block;
}

.status::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: var(--secondary-color);
  bottom: -5px;
  left: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}

.status:hover::after {
  transform: scaleX(1);
}

.status.animate {
  animation: pulseStatus 2s infinite;
}

@keyframes pulseStatus {
  0% {
    color: var(--primary-color);
  }
  50% {
    color: var(--secondary-color);
  }
  100% {
    color: var(--primary-color);
  }
}

.progress-container {
  width: 100%;
  background: #333333;
  border-radius: 10px;
  overflow: hidden;
  height: 15px;
  margin: 15px 0;
  position: relative;
}

.progress-bar {
  height: 100%;
  background: var(--secondary-color);
  width: 0%;
  transition: width 0.5s ease-in-out, background 0.5s ease-in-out;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 5px var(--secondary-color);
  }
  50% {
    box-shadow: 0 0 15px var(--secondary-color);
  }
  100% {
    box-shadow: 0 0 5px var(--secondary-color);
  }
}

.results-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.result-card {
  background: var(--card-background);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 3px 10px var(--shadow-color);
  text-align: left;
  font-size: 0.85rem;
  color: var(--text-color);
  transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease, background var(--transition-speed) ease;
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1 1 40%;
  min-width: 180px;
  opacity: 0;
  transform: translateY(10px);
  animation: fadeInUp 0.5s forwards;
}

.result-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px var(--shadow-color);
  background: #2a2a2a;
}

.result-card h3 {
  font-size: 0.9rem;
  font-weight: bold;
}

.result-card p {
  margin: 4px 0;
  font-size: 0.8rem;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Transparentan speedometer - samo obrub i tekst */
.speedometer-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  background: transparent; /* Bez pozadine */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  box-shadow: none; /* Uklonjen box-shadow */
}

.speedometer-circle {
  width: 250px;
  height: 250px;
  background: none; /* Bez pozadine, potpuno prozirno */
  border-radius: 50%;
  border: 8px solid var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: pulseBorder 1.5s infinite ease-in-out;
}

@keyframes pulseBorder {
  0% {
    box-shadow: 0 0 10px var(--secondary-color);
  }
  50% {
    box-shadow: 0 0 30px var(--secondary-color);
  }
  100% {
    box-shadow: 0 0 10px var(--secondary-color);
  }
}

.speedometer-speed {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 0 10px var(--secondary-color);
  text-align: center;
  padding: 20px;
}

@media (max-width: 1200px) {
  .app-container {
    max-width: 600px;
    padding: 20px;
    padding-top: calc(var(--header-height) + 30px);
  }

  .header h1 {
    font-size: 1.4rem;
  }

  .btn-primary {
    padding: 10px 25px;
    font-size: 0.85rem;
  }

  .progress-container {
    height: 14px;
  }

  .status {
    font-size: 0.95rem;
  }

  .result-card {
    font-size: 0.8rem;
    padding: 12px;
  }

  .speed-meters {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 900px) {
  .app-container {
    margin: 20px;
    padding: 15px;
    padding-top: calc(var(--header-height) + 30px);
  }

  .header h1 {
    font-size: 1.3rem;
  }

  .btn-primary {
    padding: 8px 20px;
    font-size: 0.85rem;
  }

  .progress-container {
    height: 14px;
  }

  .status {
    font-size: 0.9rem;
  }

  .result-card {
    font-size: 0.75rem;
    padding: 10px;
  }
}

@media (max-width: 600px) {
  .app-container {
    margin: 10px;
    padding: 10px;
    padding-top: calc(var(--header-height) + 30px);
  }

  .header h1 {
    font-size: 1.1rem;
  }

  .btn-primary {
    width: 100%;
    padding: 8px 0;
    font-size: 0.85rem;
  }

  .progress-container {
    height: 14px;
  }

  .status {
    font-size: 0.85rem;
    margin-bottom: 15px;
  }

  .result-card {
    font-size: 0.75rem;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 8px;
  }

  .result-card .icon {
    font-size: 1rem;
  }

  .speed-meters {
    flex-direction: column;
    gap: 10px;
  }

  .speedometer-overlay {
    width: 200px;
    height: 200px;
  }

  .speedometer-circle {
    width: 180px;
    height: 180px;
    border: 6px solid var(--secondary-color);
  }

  .speedometer-speed {
    font-size: 1.8rem;
  }
}